import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Hook for managing search parameters in the URL.
 */
export const useSearchParams = () => {
  const history = useHistory();
  const { search, pathname, state } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  /**
   * Sets the search parameters in the URL.
   * @param {Function|URLSearchParams} paramsUpdateFn - The setter function or the new search parameters.
   * @param {boolean} [forceUpdate=true] - Determines whether to force an update even if the parameters are the same.
   */
  const setSearchParams = (paramsUpdateFn, forceUpdate = true) => {
    const params =
      typeof paramsUpdateFn === 'function'
        ? paramsUpdateFn(new URLSearchParams(searchParams))
        : paramsUpdateFn;

    if (!forceUpdate && searchParams.toString() === params.toString()) return;
    history.push(`${pathname}?${params.toString()}`, state);
  };

  /**
   * Sets a single search parameter in the URL.
   * @param {string} key - The key of the search parameter.
   * @param {string} value - The value of the search parameter.
   */
  const setSearchParam = (key, value) =>
    setSearchParams(params => {
      params.set(key, value);
      return params;
    });

  /**
   * Deletes a search parameter from the URL.
   * @param {string} key - The key of the search parameter to delete.
   */
  const deleteSearchParam = key =>
    setSearchParams(params => {
      params.delete(key);
      return params;
    }, false);

  /**
   * Resets all search parameters in the URL.
   */
  const resetSearchParams = () => setSearchParams(new URLSearchParams());

  return {
    searchParams,
    setSearchParams,
    setSearchParam,
    deleteSearchParam,
    resetSearchParams,
  };
};

/**
 * Hook for managing a single search parameter in the URL.
 *
 * @param {string} key - The key of the search parameter.
 */
export const useSearchParam = key => {
  const { searchParams, setSearchParam, deleteSearchParam } = useSearchParams();

  const value = searchParams.get(key);
  const clearValue = () => deleteSearchParam(key);
  const setValue = value => {
    if (!value) return clearValue();
    setSearchParam(key, value);
  };

  return {
    value,
    setValue,
    clearValue,
  };
};
